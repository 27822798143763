<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <com-list-search>
                <el-form :inline="true" size="small" slot="list-search-before">
                    <el-form-item>
                        <el-input placeholder="订单号" style="width: 240px" clearable
                                  v-model="form.orderNo"/>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="携程订单号" style="width: 240px" clearable
                                  v-model="form.masterOrderId"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="事件类型" :options="workOrderTypeOptions" clearable
                                   v-model="form.eventSource"/>
                    </el-form-item>
                    <el-form-item>
                        <ApiSelect placeholder="事件状态" :options="workOrderStatusOptions" clearable
                                   v-model="form.status"/>
                    </el-form-item>
                </el-form>
                <div slot="list-search-after" style="width: 170px;display: flex;justify-content: flex-end">
                    <el-button @click="resetClick()">重置</el-button>
                    <el-button type="primary" @click="getList()" :loading="loading">搜索</el-button>
                </div>
            </com-list-search>
            <SectionContent>
                <div v-loading="loading">
                    <div v-if="result.total>0">
                        <div v-for="vm in result.data" :key="vm.id" class="list-item">
                            <div class="list-item_hd">
                                <div style="flex: 1;display: flex;align-items: center">
                                    <!--                                    <el-tag effect="dark"-->
                                    <!--                                            :type="vm.eventSource | formatDesc(workOrderTypeOptions,'value','type')">-->
                                    <!--                                        {{ vm.eventSource | formatDesc(workOrderTypeOptions) }}-->
                                    <!--                                    </el-tag>-->
                                    <el-link :underline="false"
                                             :type="vm.status | workOrderStatusOptionsFormat('type',vm)">
                                        {{ vm.status | workOrderStatusOptionsFormat('label',vm) }}
                                    </el-link>
                                    <span class="ml10" v-if="vm.orderNo">
                                        订单号：
                                         <a class="color-primary" :href="'#/userCarList?orderNo='+vm.orderNo">
                                             {{ vm.orderNo }}
                                         </a>
                                    </span>
                                    <span class="ml10" v-if="vm.masterOrderId"> 携程订单号：{{ vm.masterOrderId }}</span>
                                </div>
                                <div v-if="vm.deadLine">
                                    指标时间：{{ vm.deadLine }}
                                </div>
                            </div>
                            <div class="list-item_bd">
                                <div>
                                    <el-tag type="primary" effect="plain" size="mini" v-if="vm.eventSource === 1">
                                        {{ vm.eventDetail.eventTypeName }}
                                    </el-tag>
                                    <el-tag size="mini"
                                            effect="plain"
                                            v-if="vm.eventSource === 2&&vm.appealable==='1'"
                                            :type="vm.appealStatus | formatDesc(appealStatusOptions,'value','type')">
                                        {{ vm.appealStatus | formatDesc(appealStatusOptions) }}
                                    </el-tag>
                                    <el-tag size="mini"
                                            effect="plain"
                                            v-if="vm.eventSource === 2&&vm.appealable!=='1'" type="info">
                                        不可申诉
                                    </el-tag>
                                    <div class="mt5" v-ellipsis="{rows:3,lineHeight:20}" v-html="vm.content"></div>
                                </div>
                                <div>
                                    <p style="font-size: 13px;color: #999">产品类型</p>
                                    {{ vm.parentCategoryCode === 'jnt' ? '接送' : '包车' }}
                                </div>
                                <div style="text-align: right">
                                    <el-button type="text" @click="handleDetail(vm)">查看</el-button>
                                    <el-button type="text" class="ml10"
                                               v-if="vm.createType===1&&vm.status===0&&(vm.eventSource===1||(vm.eventSource===2&&vm.appealable==='1'))"
                                               @click="handleDetail(vm)">处理
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-empty v-else></el-empty>
                </div>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
        <WorkOrderDetailDialog ref="workOrderDetailDialogRef" @onChange="handleOnChange"></WorkOrderDetailDialog>
    </div>
</template>

<script>
import {workOrderStatusOptions, workOrderTypeOptions, appealStatusOptions} from '@/data/workOrder'
import comListSearch from "../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import dayjs from "dayjs";
import {getWorkOrderList} from "@/www/urls/workOrder";
import WorkOrderDetailDialog from "@/www/pages/workOrder/WorkOrderDetailDialog.vue";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    orderNo: undefined,
    masterOrderId: undefined,
    eventSource: undefined,
    status: undefined,
})
export default {
    name: "WorkOrder",
    data() {
        return {
            workOrderStatusOptions,
            workOrderTypeOptions,
            appealStatusOptions,
            loading: true,
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    filters: {
        workOrderStatusOptionsFormat(value, key, record) {
            if (value === 0 && record.createType === 0)
                return key === 'label' ? '已发送' : 'primary';
            return workOrderStatusOptions.find((item) => item.value === value)[key]
        }
    },
    components: {
        comListSearch,
        SectionContent,
        WorkOrderDetailDialog,
    },
    mounted() {
        this.getList();
    },
    methods: {
        showEdit(record) {
            return record.status === 0 && dayjs(record.pushTime).isAfter(dayjs())
        },
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getWorkOrderList(this.form)
            this.loading = false
            if (ret.success) {
                ret.data.data = ret.data.data.map((item) => ({
                    ...item,
                    eventDetail: item.eventDetail ? JSON.parse(item.eventDetail) : {},
                    punishDetail: item.punishDetail ? JSON.parse(item.punishDetail) : {},
                }))
                this.result = ret.data;
            } else this.$message.error(ret.errors[0].message)

        },
        resetClick() {
            this.form = createForm();
            this.getList(1)
        },
        handleDetail(record) {
            if (record.eventSource === 1)
                this.$refs.workOrderDetailDialogRef.open(record);
            else
                this.$router.push(`/workOrderDetail?id=${record.ctripEventId}`)
        },
        handleOnChange(record) {
            this.result.data = this.result.data.map((item) => ({
                ...item,
                ...(record.id === item.id ? record : {})
            }))
        }
    }
}
</script>

<style scoped lang="scss">
.common-list-search::v-deep {
    .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }
}

.list-item {
    border: 1px solid #f2f4f6;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    background-color: #fff;

    &:hover {
        box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

    .list-item_hd {
        border-bottom: 1px solid #f2f4f6;
        display: flex;
        padding-bottom: 10px;
    }

    .list-item_bd {
        padding-top: 5px;
        display: flex;

        & > div:not(:first-child) {
            flex: 1;
        }

        & > div:first-child {
            width: 50%;
            padding-right: 50px;
        }
    }
}
</style>
